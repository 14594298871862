import * as React from "react";
import { Link } from "gatsby";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  NotFoundWrapper,
  NotFoundContent,
  NotFoundImage,
  Goback,
  Icon,
} from "./style";

interface NotFoundProps {}

const NotFound: React.FunctionComponent<NotFoundProps> = () => {
  return (
    <NotFoundWrapper>
      <NotFoundContent>
        <h1>Página perdida</h1>
        <p>
          La página que estabas buscando no existe más. Trata de buscar de nuevo
          o regresa con el botón de abajo.
        </p>
        <Goback>
          <Link to="/">
            <Icon>
              <IoMdArrowRoundBack />
            </Icon>
            REGRESAR
          </Link>
        </Goback>
      </NotFoundContent>
      <NotFoundImage>
        <img src="https://media.giphy.com/media/g01ZnwAUvutuK8GIQn/source.gif" />
      </NotFoundImage>
    </NotFoundWrapper>
  );
};

export default NotFound;
